<template>
  <win-block>

    <template #win-title-left>Выписки</template>

    <template #actions>
      <v-btn outlined color="primary"
             :disabled="!items_selected.length"
             @click="btnClearList">
        Очистить
      </v-btn>
      <v-spacer/>
      <!--      <v-btn outlined color="primary">-->
      <!--        Сохранить PDF-->
      <!--      </v-btn>-->
      <v-btn outlined color="primary"
             :disabled="!items_selected.length"
             @click="openHTMLpage()">
        открыть в HTML
      </v-btn>
      <!--      <v-btn depressed color="primary"-->
      <!--             :disabled="!items_selected.length"-->
      <!--      >-->
      <!--        Обновить БД-->
      <!--      </v-btn>-->
    </template>

    <vue-dropzone
        id="customdropzone"
        :options="dropzoneOptions"
        :useCustomSlot=true

        @vdropzone-file-added="vdropzone_file_added"
        @vdropzone-success="vdropzone_success"
        @vdropzone-upload-progress="vdropzone_upload_progress"
        @vdropzone-error="vdropzone_error"
    >

      <div class="dropzone-custom-content">
        <h3 class="dropzone-custom-title">Перетяните файлы в эту зону для загрузки на сервер</h3>
        <div class="subtitle">...или кликните для выбора файлов</div>
        <v-btn color="primary" outlined class="mt-3">
          <v-icon>mdi-cloud-upload</v-icon>
        </v-btn>
      </div>


    </vue-dropzone>

    <v-list two-line>
      <v-list-item-group
          class="dropzone dz-mod text-left"
          v-model="items_selected"
          active-class="primary--text"
          multiple
      >
        <div v-for="attach in attachments" :key="attach.id" class="dz-preview dz-file-preview" :class="attach.class"
             STYLE="width: 100%">
          <v-divider/>
          <attachment :data='attach'/>

          <!--          <div class="dz-progress">-->
          <!--            <span class="dz-upload" data-dz-uploadprogress :style="{width:attach.progress + '%'}"></span>-->
          <!--          </div>-->
          <div class="dz-success-mark">
            <v-icon x-large>mdi-checkbox-marked-circle</v-icon>
          </div>
          <div class="dz-error-mark">
            <v-icon x-large>mdi-close-circle</v-icon>
          </div>
          <div class="dz-error-message"><span data-dz-errormessage></span></div>
        </div>
      </v-list-item-group>
    </v-list>

  </win-block>
</template>

<script>

import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  data() {
    return {
      items_selected: [],
      attachments: [],
      dropzoneOptions: {
        url: `${this.$store.state.domen}${this.$url.server_project_uploadExtract}`,
        // url:  `https://httpbin.org/post`,
        useCustomSlot: true,
        previewsContainer: false,
        withCredentials: false,
        parallelUploads: 1,
        maxFilesize: 1000000,
        createImageThumbnails: false,
        acceptedFiles: '.zip', //'.pdf, .docx, .doc' фильтр расширений в окне файлов
        maxFiles: 100, // максимум файлов для заргузки


        //
        headers: {
          "access-control-request-headers": "access-control-allow-headers,authorization,x-requested-with",
          "Authorization": " Token " + localStorage.getItem('access_token') || null,
          "Cache-Control": null,
        }
        //
        // clickable:false, // отключить всю кликабельность
        // previewTemplate: this.template(),
        // "include-styling":"false",
        // thumbnailWidth: 250,
        // thumbnailHeight: 140,
      }
    };
  },
  methods: {
    btnClearList() {
      this.attachments = []
      this.items_selected = []
    },
    vdropzone_file_added(file) {
      // console.log("1 vdropzone_file_added ", file)
      let attachment = {};
      attachment.id = file.upload.uuid;
      attachment.filename = file.name;
      attachment.extension = "." + file.type.split("/")[1];
      attachment.thumb = file.dataURL;
      attachment.thumb_list = file.dataURL;
      // attachment.isLoading = true;
      attachment.status = 'loading'
      attachment.progress = null;
      attachment.size = file.size;
      attachment.class = ''
      attachment.bytesSent = 0
      attachment.textLine1 = ''
      attachment.textLine2 = ''
      attachment.textLine3 = ''
      attachment.textLine4 = attachment.filename

      this.attachments = [attachment, ...this.attachments];
      this.$forceUpdate();
    },
    vdropzone_upload_progress(file, progress, bytesSent) {
      // console.log("2.1 uploadProgress ", file, progress)
      this.attachments.map(attachment => {
        if (attachment.id === file.upload.uuid) {
          attachment.progress = `${Math.floor(progress)}`;
          attachment.bytesSent = bytesSent
        }
      });
    },
    vdropzone_success(file, response) {
      // console.log("2 vdropzone_success ", file, response)
      this.attachments.map(attachment => {
        if (attachment.id === file.upload.uuid) {
          attachment.class = "dz-complete dz-success"
          attachment.textLine1 = `${response.owners[0].FIO}`
          let owners = response.owners.length
          if (owners > 1)
            attachment.textLine1 = `${attachment.textLine1} + ${owners - 1} собственник(ов)`
          attachment.textLine2 = response.owners[0].Registration
          //инвертируем строку
          let text = response.Address.split(',').reverse()  // кв.269, д. 9, улица Белорусская, город Одинцово ...
          attachment.textLine3 = text.toString();
          attachment.textLine4 = `${attachment.filename} - ${response.Cadastral}`
          attachment.item = response
          attachment.status = 'complete'
        }
      });
    },
    vdropzone_error(file, message, xhr) {
      // console.log("3 vdropzone_error ", message, xhr)
      this.attachments.map(attachment => {
        if (attachment.id === file.upload.uuid) {
          attachment.status = 'error'
          attachment.textLine1 = 'ОШИБКА!'
          if (!xhr) {
            attachment.textLine2 = message
          } else {
            attachment.textLine2 = xhr.statusText
            //// Работает только в режиме разработки
            // attachment.textLine3 = message.split('Request Method')[0].split('\n')[1].trim()
          }

        }
      });
    },
    openHTMLpage() {
      // console.log(this.items_selected);
      this.items_selected.forEach((item_index) => {
        let attach = this.attachments[item_index]
        if (attach.status !== 'complete') return
        var multipage = window.open(`${this.$store.state.domen}/server/api/v1/project/extractToHTML?name=${attach.item.name}`, '_blank');
        multipage // неоходимо чтобы браузер не блокировал открытие мультистраниц
      });
    },
  },
  components: {
    "win-block": () => import('@/site/componets/winblock'),
    vueDropzone: () => import("vue2-dropzone"),
    attachment: () => import("./dropzone_item"),
    // "dropzone": () => import('./dropzone'),
    // "vue-dropzone": () => import('vue2-dropzone'),
  },
}

var docs = [
  {
    "Cadastral": "50:20:0020410:215",
    "Address": "Московская область, город Одинцово, улица Белорусская, д. 9, кв. 215",
    "owners": [
      {
        "FIO": "XXXX xxxx xxxx",
        "Registration": "Собственность, № 50:20:0020410:214-50/148/2021-6 от 30.08.2021"
      }
    ],
    "Apartment": "k122",
    "filename": "extract-1.json"
  },
  {
    "Cadastral": "50:20:0020410:214",
    "Address": "Московская область, город Одинцово, улица Белорусская, д. 9, кв. 122",
    "owners": [
      {
        "FIO": "Крючкова Юлия Игоревна",
        "Registration": "Собственность, № 50:20:0020410:214-50/148/2021-6 от 30.08.2021"
      }
    ],
    "Apartment": "k122",
    "filename": "extract-1.json"
  },
  {
    "Cadastral": "50:20:0020410:213",
    "Address": "Московская область, город Одинцово, улица Белорусская, д. 9, кв. 122",
    "owners": [
      {
        "FIO": "OOO ooo oooo",
        "Registration": "Собственность, № 50:20:0020410:214-50/148/2021-6 от 30.08.2021"
      }
    ],
    "Apartment": "",
    "filename": "extract-1.json"
  },
]
docs
</script>


<style scoped>
.dropzone.dz-mod {
  padding: unset;
  width: unset;
  min-height: unset;
  border: unset;
  background: unset;
}

.dropzone.dz-mod .dz-preview {
  margin: unset;
  min-height: unset;
}
</style>

<style lang="scss">
.z-spin {
  animation: spin-animation 1.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}
</style>